import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import Layout from '@components/layout'
import SEO from '@components/seo'
import Link from '@components/link'
import { DateSpan } from '@components/dateSpan'

interface Props {
  readonly data: PageQueryData
  readonly pageContext: {
    tag: string
  }
}

const TagTemplate: React.FunctionComponent<Props> = ({ data, pageContext }) => {
  const { tag } = pageContext
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout title={siteTitle}>
      <SEO
        title={`Posts tagged "${tag}"`}
        meta={[{
          name: 'keywords', content:[tag].toString()
        }]}
      />
      <div id="main">
        <div className="container-fluid">
          <div className="row d-flex">
            <div className="col-lg-10 px-md-5 py-5">
              <GlassWrapper className={`page-content p-3`}>
                <header>
                  <h1>Posts tagged {tag}</h1>
                </header>
                {posts.map(node => {
                  const title = node.frontmatter.title || node.fields.slug
                  return (
                    <div key={node.fields.slug}>
                      <h3>
                        <Link to={'/post' + node.fields.slug} title={title}>{title}</Link>
                      </h3>
                      <DateSpan>{node.frontmatter.date}</DateSpan>
                      <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                    </div>
                  )
                })}
              </GlassWrapper>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

const GlassWrapper = styled.div`
  background: rgba( 255, 255, 255, 0.20 );
  box-shadow: 0 8px 20px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 2px );
  -webkit-backdrop-filter: blur( 2px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  color: #fff;
`;

export default TagTemplate

interface PageQueryData {
  site: {
    siteMetadata: {
      title: string
    }
  }
  allMarkdownRemark: {
    totalCount: number
    nodes: {
      excerpt: string
      fields: {
        slug: string
      }
      frontmatter: {
        date: string
        title: string
      }
    }[]
  }
}

export const pageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000, filter: {frontmatter: {tags: {in: [$tag]}}}) {
      totalCount
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
        }
      }
    }
  }
`
